import React from 'react';
import styles from './pagebreak.module.css'

const Pagebreak_2 = () => {
    return (
        <div className={` ${styles.pageBreak} ${styles.pageBreak2}` }>
        </div>
    );
}

export default Pagebreak_2;
