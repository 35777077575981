import React from 'react';
import styles from './pagebreak.module.css'

const Pagebreak_1 = () => {
    return (
        <div className={`${styles.pageBreak} ${styles.pageBreak1}` }>
        </div>
    );
}

export default Pagebreak_1;
